export function formatParams(origin, params) {
  if (!params) return;
  if (!origin) return params;
  let result = {};
  Object.keys(params).forEach((key) => {
    if (params[key] !== origin[key]) {
      result[key] = params[key];
    }
  });
  if (Object.keys(result).length === 0) result = undefined;
  return result;
}

export function filterValues(keys, data) {
  const result = {};
  if (!keys || keys.length === 0) {return result;}
  Object.keys(data).forEach((key) => {
    for (let i = 0; i < keys.length; i++) {
      if (key === keys[i]) {
        result[key] = data[key];
        break;
      }
    }
  });
  return result;
}

// 数组根据字段进行排序，可选择升序或降序,并返回新数组
export function sortByField(arr, field, order = "asc") {
  if (!arr || !arr.length) return [];
  return arr.sort((a, b) => {
    if (order === "asc") {
      return a[field] - b[field];
    } else {
      return b[field] - a[field];
    }
  });
}

export function getRandStr(len=6) {
  let randStr = '';
  const arr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
  for (let i = 0; i < len; i++) {
    randStr += arr[Math.round(Math.random() * (arr.length - 1))]
  }
  return randStr;
}

export function modalText(text) {
  if (text === 'cancel') {
    return '取消';
  } else if (text === 'ok') {
    return '确定';
  }
  return '';
}

export function jumpType(val, destination) {
  let letter = '-';
  if (val === 1) {
    letter = '跳转到商品[' + destination + ']';
  } else if (val === 2) {
    letter = '跳转到专题[' + destination + ']';
  }
  return letter;
}


export function get_img_url(val) {
  val = val.trim();
  let url = val;
  let place = '/images/';
  let i = val.lastIndexOf(place);
  if (i !== -1) {
    url = val.substring(i + place.length - 1);
  }
  return url;
}


/**
 * 处理对象参数值，排除对象参数值为 '',null,undefined 并返回一个新对象
 **/
export function filter_obj_empty(obj){
  let param = {};
  if ( obj === null || obj === undefined || obj === '' ) return param;
  for (let key in obj ){
    if (typeof (obj[key]) === 'object'){
      param[key] = filter_obj_empty(obj[key]);
    } else if(obj[key] !== null && obj[key] !== undefined && obj[key] !== ''){
      param[key] = obj[key];
    }
  }
  return param;
}

export function check_order_num(list) {
  let index = -1;
  if (list && list.length > 0){
    for (let i = 0; i < list.length; i++) {
      if (!list[i].order_num) {
        index = i;
        break;
      }
    }
  }
  return index;
}

export function sleep(millisecond) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, millisecond)
  })
}

export function get_from_array() {
  return [
    {value: 1, text: '微信小程序'},
    {value: 2, text: '抖音小程序'},
    {value: 3, text: '支付宝小程序'},
    {value: 4, text: 'H5'},
    {value: 5, text: 'PC'},
    {value: 0, text: '未知'},
  ];
}

export function get_catetory_type_array() {
  return [
    {value: 0, text: '未知'},
    {value: 1, text: '商品'},
    {value: 2, text: '文章'},
  ];
}

export function get_catetory_classify_array() {
  return [
    {value: 0, text: '无类别'},
    {value: 11, text: '小程序'},
    {value: 12, text: '好物'},
    {value: 21, text: '笔记'},
    {value: 22, text: '兴趣'},
    {value: 23, text: '作品'},
  ];
}

export function get_member_level_array() {
  return [
    {value: 100, text: '初级'},
    {value: 200, text: '青铜'},
    {value: 300, text: '白银'},
    {value: 400, text: '黄金'},
    {value: 500, text: '铂金'},
    {value: 600, text: '钻石'},
  ];
}


export function get_trade_type_array() {
  return [
    {value: 1, text: '付款码支付'},
    {value: 2, text: 'JSAPI支付'},
    {value: 3, text: 'NATIVE支付'},
    {value: 4, text: 'App支付'},
    {value: 5, text: '刷脸支付'},
  ];
}

export function get_trade_status_array() {
  return [
    {value: 1, text: '支付成功'},
    {value: 2, text: '转入退款'},
    {value: 3, text: '已撤销'},
  ];
}

export function get_refund_status_array() {
  return [
    {value: 1, text: '退款成功'},
    {value: 2, text: '退款处理中'},
    {value: 3, text: '退款失败'},
    {value: 4, text: '退款异常'},
  ];
}

export function get_logistics_type_array() {
  return [
    {value: 1, text: '快递物流'},
    {value: 2, text: '同城配送'},
    {value: 3, text: '虚拟发货'},
    {value: 4, text: '用户自提'},
  ];
}